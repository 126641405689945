import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Headline from '../ui/Headline'
import LoadMore from '../ui/LoadMore'
import {listPlantData, listPlants, listPlantStatuses} from '../../api/plants'
import {listData as listDataReports} from '../../api/reports'
import {DATA_TYPE_PLANTS, RANGE_TYPE_DAILY, RANGE_TYPE_MONTHLY} from '../../constants/reports'
import {SERVICES_IDS_JA} from '../../constants/services'

// tmp
import {useLocation} from 'react-router-dom'


const Container = styled.div`
  margin: ${props => `${props.theme.spacing[3]} 0`}
`

const LoadMoreContainer = styled.div`
  // margin: ${props => `${props.theme.spacing[3]} auto`}
  display: flex;
  justify-content: center;
`

const Table = styled.table`
  background-color: #FFFFFF;
  border-collapse: collapse;
  margin: ${props => `${props.theme.spacing[1]} 0`};
  width: 100%;
`

const Td = styled.td`
  border: ${props => `solid ${1 / props.theme.vw}vw #27ACD7`};
  padding: ${props => `${props.theme.spacing[2]} ${props.theme.spacing[2]}`};
`

const Th = styled.th`
  background-color: #27ACD7;
  color: #FFFFFF;
  padding: ${props => `${props.theme.spacing[2]} ${props.theme.spacing[2]}`};
  text-align: left;
`

const Pagination = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0
`

const PaginationItem = styled.li`
  background-color: ${props => props.active ? '#EDEDED' : '#FFFFFF'};
  border: ${props => `solid ${1 / props.theme.vw}vw #27ACD7`};
  border-radius: ${props => props.theme.radius[1]};
  cursor: pointer;
  margin-right: ${props => props.theme.spacing[2]};
  margin-top: ${props => props.theme.spacing[2]};
  padding: ${props => props.theme.spacing[1]};
`

const PlantListTable = () => {
  const [pagination, setPagination] = useState({})
  const [page, setPage] = useState(1)
  const [plantDataMap, setPlantDataMap] = useState({})
  const [plantStatusesMap, setPlantStatusesMap] = useState({})
  const [sort, setSort] = useState({ascending: false, key: 'id'})

  const {search} = useLocation()


  const handleClickLoadMore = e => {
    setPage(page + 1)
  }

  const handleClickPaginationItem = newPage => e => {
    if(newPage == page) {
      return
    }
    setPage(newPage)
  }

  const paginate = async (page) => {
    return await listPlants({
      fields: ['id', 'name', 'service_id'],
      limit: 10,
      page: page || 1,
    })
  }

  useEffect(() => {
    (async () => {
      let r = await paginate(page)
      if(pagination.data) {
        r.data = pagination.data.concat(r.data)
      }
      setPagination(r)
    })()
  }, [page])

  useEffect(() => {
    if(!pagination.data) {
      return
    }

    const plantIds = pagination.data.map(item => {
      const s = item.id.toString()
      if(plantDataMap.hasOwnProperty(s) || plantStatusesMap.hasOwnProperty(s)) {
        return null
      }
      return item.id
    }).filter(item => item)

    if(!plantIds) {
      return
    }

    (async () => {
      let promises = []
      if(search.indexOf('reports=reports') === -1) {
        promises.push(listPlantData({
          fields: ['plant_id', 'kwh_daily', 'kwh_monthly'],
          limit: -1,
          plantIds,
        }))
      }
      else {
        let q = {
          data_type: DATA_TYPE_PLANTS,
          fields: ['date_time', 'power', 'plant_id'],
          plant_ids: plantIds,
        }

        const now = new Date()
        const year = now.getFullYear()
        const month = now.getMonth()
        const day = now.getDate()

        const qd = {
          start: new Date(year, month, day),
          end: new Date(year, month, day),
          range_type: RANGE_TYPE_DAILY,
          groupby: 'plant_id',
          ...q
        }

        const qm = {
          start: new Date(year, month, 1),
          end: new Date(year, month + 1, 0),
          range_type: RANGE_TYPE_MONTHLY,
          groupby: 'plant_id',
          ...q
        }
        promises = promises.concat([
          listDataReports(qd),
          listDataReports(qm),
        ])
      }

      promises.push(listPlantStatuses({
        fields: ['plant_id', 'status_id', 'status_text'],
        limit: -1,
        plantIds,
      }))

      const rs = await Promise.all(promises)

      let pdm = {}
      if(search.indexOf('reports=reports') === -1) {
        rs[0].data.map(item => {
          pdm[item.plant_id.toString()] = item
        })
      }
      else {
        for (let i = 0; i < plantIds.length; i++) {
          pdm[plantIds[i].toString()] = {
            plant_id: plantIds[i],
            kwh_daily: 0,
            kwh_monthly: 0,
          }
        }
        for (let i = 0; i < rs[0].length; i++) {
          const plantId = rs[0][i].plant_id.toString()
          pdm[plantId]['kwh_daily'] += rs[0][i].power
        }

        for (let i = 0; i < rs[1].length; i++) {
          const plantId = rs[1][i].plant_id.toString()
          pdm[plantId]['kwh_monthly'] += rs[1][i].power
        }
      }
      setPlantDataMap({...plantDataMap, ...pdm})

      let psm = {}
      rs[rs.length - 1].data.map(item => {
        psm[item.plant_id.toString()] = item
      })
      setPlantStatusesMap({...plantStatusesMap, ...psm})
    })()
  }, [pagination])

  return (
    <Container>
      <Headline fontSize="xl">発電所リスト</Headline>
      {pagination.data &&
        <Table>
          <thead>
            <tr>
              <Th>物件名</Th>
              <Th>今日の発電量</Th>
              <Th>今月の発電量</Th>
              <Th>運転状況</Th>
              <Th>遠隔監視システム</Th>
            </tr>
          </thead>
          <tbody>
            {pagination.data.map(item => {
              const pd = plantDataMap[item.id.toString()]
              const ps = plantStatusesMap[item.id.toString()]
              return (
                <tr key={item.id}>
                  <Td>{item.name}</Td>
                  <Td>{(pd && pd.kwh_daily) ? `${pd.kwh_daily.toFixed(1)} kWh` : ''}</Td>
                  <Td>{(pd && pd.kwh_monthly) ? `${pd.kwh_monthly.toFixed(1)} kWh` : ''}</Td>
                  <Td>{ps ? ps.status_text : ''}</Td>
                  <Td>{SERVICES_IDS_JA[item.service_id.toString()]}</Td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      }
      {page < pagination.pages &&
        <LoadMoreContainer>
          <LoadMore onClick={handleClickLoadMore}/>
        </LoadMoreContainer>
      }
      {/**/}
      {/*{(pagination.pages && pagination.pages > 1) &&
        <Pagination>
          {[...Array(pagination.pages).keys()].map(i => {
            const n = i + 1
            return (
              <PaginationItem
                active={n == page}
                key={i}
                onClick={handleClickPaginationItem(n)}
              >
                {n}
              </PaginationItem>
            )
          })}
        </Pagination>
      }*/}
    </Container>
  )
}

export default PlantListTable
